import React from 'react';
import { connect } from 'react-redux';
import { renderField, compileSchema } from './liform-react/index'
import Theme from './liform-react/themes/material-ui'

const BaseForm = props => {
    const { source, theme, context } = props;
    const schema = compileSchema(props.schema);
    return (
        renderField(schema, '', theme || Theme, source, context, true)
    );
  };
  

const ConfigInputView = ({ schema, source, context }) => (
    (schema && <BaseForm schema={schema} source={source} context={context} />)
);

const mapStateToProps = (state, props) => {
    let configs = Object.values(state.admin.resources[props.reference].data)
    let config = configs.find(x => x.integrationId == props.integrationId && x.systemId == props.systemId)

    return {
        // Get the record by its id from the react-admin state.
        schema: state.admin.resources[props.reference]
            ? (config != null ? config.configurationSchema : null)
        : null,
        version: state.admin.ui.viewVersion,
    }
};
  
const ConfigInput = connect(
    mapStateToProps,
    {}
)(ConfigInputView);

export default ConfigInput;